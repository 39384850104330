<template>
  <b-col cols="2">
    <b-button block v-b-modal.create-gr-modal variant="outline-secondary"
      >{{ this.edit ? 'Edit' : 'Create' }} Gold Rush Draw</b-button
    >

    <b-modal
      id="create-gr-modal"
      :title="this.edit ? 'Edit Gold Rush Event Series' : 'Create Gold Rush Event Series'"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <form @submit.stop.prevent="onSubmit">
        <div v-if="createMasterEventFailed" class="alert alert-danger">
          Failed to create event series: {{ errorMessage }}
        </div>

        <b-form-group label="Event name" label-for="event-name" :invalid-feedback="veeErrors.first('event-name')">
          <b-form-input
            name="event-name"
            v-model="eventName"
            v-validate="{ required: true }"
            :state="validateState('event-name')"
            aria-describedby="event-name-feedback"
            data-vv-as="event name"
          />
        </b-form-group>

        <b-form-group
          label="Description"
          label-for="event-description"
          :invalid-feedback="veeErrors.first('event-description')"
        >
          <b-form-input
            name="event-description"
            v-model="description"
            v-validate="{ required: true }"
            :state="validateState('event-description')"
            aria-describedby="event-description-feedback"
            data-vv-as="description"
          />
        </b-form-group>

        <b-form-row>
          <b-form-group
            label="Number of events"
            label-for="event-count"
            class="col"
            :invalid-feedback="veeErrors.first('event-count')"
          >
            <b-form-spinbutton
              name="event-count"
              v-model="eventCount"
              class="valid-without-checkmark"
              v-validate="{
                required: true,
                numeric: true,
                min_value: 1,
                max_value: 52
              }"
              min="1"
              max="52"
              :state="validateState('event-count')"
              aria-describedby="event-count-feedback"
              data-vv-as="number of events"
              @change="calculateEndDate()"
            />
          </b-form-group>

          <b-form-group
            label="Days per event"
            label-for="event-days"
            class="col"
            :invalid-feedback="veeErrors.first('event-days')"
          >
            <b-form-spinbutton
              name="event-days"
              v-model="daysPerEvent"
              class="valid-without-checkmark"
              v-validate="{
                required: true,
                numeric: true,
                min_value: 1
              }"
              min="1"
              max="365"
              :state="validateState('event-days')"
              aria-describedby="event-days-feedback"
              data-vv-as="days per event"
              @change="calculateEndDate()"
            />
          </b-form-group>

          <b-form-group
            label="Auto recurring"
            label-for="auto-recurring"
            :invalid-feedback="veeErrors.first('auto-recurring')"
          >
            <b-form-select
              name="auto-recurring"
              v-model="autoRecurring"
              aria-describedby="auto-recurring-feedback"
              :options="options"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group
            label="Start date"
            label-for="event-start-date"
            class="col"
            :invalid-feedback="veeErrors.first('event-start-date')"
          >
            <b-form-datepicker
              name="event-start-date"
              v-model="startDate"
              :min="new Date()"
              v-validate="{
                required: true
              }"
              :state="validateState('event-start-date')"
              aria-describedby="event-start-date-feedback"
              data-vv-as="start date"
              @input="calculateEndDate()"
            />
          </b-form-group>

          <b-form-group label="End date" label-for="event-end-date" class="col">
            <b-form-datepicker name="event-end-date" v-model="endDate" disabled />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group
            label="Start time"
            label-for="event-start-time"
            :invalid-feedback="veeErrors.first('event-start-time')"
            class="col"
          >
            <b-form-timepicker
              name="event-start-time"
              v-model="startTime"
              v-validate="{
                required: true
              }"
              :state="validateState('event-start-time')"
              aria-describedby="event-start-time-feedback"
              data-vv-as="start time"
            />
          </b-form-group>

          <b-form-group
            label="End time"
            label-for="event-end-time"
            :invalid-feedback="veeErrors.first('event-end-time')"
            class="col"
          >
            <b-form-timepicker
              name="event-end-time"
              v-model="endTime"
              v-validate="{
                required: true
              }"
              :state="validateState('event-end-time')"
              aria-describedby="event-end-time-feedback"
              data-vv-as="end time"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group
            label="Starting ticket number"
            label-for="start-ticket-number"
            :invalid-feedback="veeErrors.first('start-ticket-number')"
            class="col"
          >
            <b-form-spinbutton
              name="start-ticket-number"
              v-model="startingTicketNumber"
              class="valid-without-checkmark"
              v-validate="{
                required: true,
                numeric: true,
                min_value: 1
              }"
              min="1"
              max="10000"
              :state="validateState('start-ticket-number')"
              aria-describedby="start-ticket-number-feedback"
              data-vv-as="starting ticket number"
            />
          </b-form-group>

          <b-form-group label="Print individual tickets" label-for="print-individual-tickets" class="col">
            <b-form-select
              name="print-individual-tickets"
              v-model="printIndividualTickets"
              aria-describedby="print-individual-tickets-feedback"
              :options="options"
            />
          </b-form-group>
        </b-form-row>

        <label for="ticket-packages">Ticket packages</label>

        <b-form-row v-for="(ticketPackage, index) in ticketPackages" :key="ticketPackage.id">
          <b-form-group class="col-4" :invalid-feedback="veeErrors.first('ticket-count-' + index)">
            <b-form-spinbutton
              :name="'ticket-count-' + index"
              class="valid-without-checkmark"
              v-model="ticketPackage.ticketCount"
              v-validate="{
                required: true,
                min_value: 1
              }"
              min="1"
              :state="validateState('ticket-count-' + index)"
              data-vv-as="ticket count"
            />
          </b-form-group>

          <div class="col-3 flex justify-center items-center mb-5">ticket(s) for</div>

          <b-form-group class="col-3" :invalid-feedback="veeErrors.first('ticket-price-' + index)">
            <b-form-input
              v-model="ticketPackage.amountDollars"
              :name="'ticket-price-' + index"
              v-validate="{
                required: true,
                decimal: 2,
                min_value: 0.01
              }"
              min="1"
              :state="validateState('ticket-price-' + index)"
              data-vv-as="ticket price"
            />
          </b-form-group>

          <div class="col flex justify-center items-center mb-4">
            <button
              v-if="index >= 1"
              type="button"
              class="
                cursor-pointer
                border border-gray-200
                bg-gray-100
                rounded-lg
                p-2
                h-9
                flex
                items-center
                justify-center
                hover:border-gray-300
                hover:bg-gray-200
              "
              @click="removeTicketPackage(index)"
            >
              <FontAwesomeIcon class="text-xl h-4" :icon="['far', 'circle-minus']" />
            </button>
          </div>
        </b-form-row>
        <b-form-row class="mb-4 justify-center mt-3">
          <button
            type="button"
            v-if="ticketPackages.length <= 4"
            class="
              cursor-pointer
              border border-green-700
              bg-green-700
              text-white
              rounded-lg
              p-2
              h-9
              flex
              items-center
              justify-center
              hover:border-green-800
              hover:bg-green-800
            "
            @click="addTicketPackage()"
          >
            Add ticket package
          </button>
        </b-form-row>
        <b-form-row>
          <b-form-group label="Ticket Footer" label-for="ticket-footer" class="col">
            <b-form-textarea v-model="ticketFooter" name="ticket-footer" data-vv-as="ticket footer" />
          </b-form-group>
        </b-form-row>
      </form>
    </b-modal>
  </b-col>
</template>

<script>
import axios from '@/axios';
import { parse, addDays } from 'date-fns';
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';

export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    masterEventData: {
      type: Object,
      default: () => {
        return {
          id: null,
          name: null,
          description: null,
          numDraws: 1,
          daysPerDraw: 7,
          autoRecurring: true,
          startDate: null,
          endDate: null,
          printIndividualTickets: false,
          startTicketNumber: 1,
          ticketPackages: [
            {
              uuid: undefined,
              id: 1,
              numTickets: 1,
              price: 1
            }
          ],
          ticketFooter: null
        };
      }
    }
  },
  data() {
    return {
      eventName: null,
      description: null,
      eventCount: 1,
      daysPerEvent: 7,
      autoRecurring: true,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      printIndividualTickets: false,
      startingTicketNumber: 1,
      ticketPackages: [
        {
          uuid: undefined,
          id: 1,
          ticketCount: 1,
          amountDollars: 1
        }
      ],
      ticketFooter: null,
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      createMasterEventFailed: false,
      errorMessage: null
    };
  },

  computed: {
    organizationTimeZone() {
      return this.$store.getters.getOrganization.timeZone;
    }
  },

  methods: {
    getLocalDate(utcDateTime, localTimeZone) {
      const localDateTime = utcToZonedTime(utcDateTime, localTimeZone);
      const formattedLocalDate = format(localDateTime, 'yyyy-MM-dd');

      return formattedLocalDate;
    },
    getLocalTime(utcDateTime, localTimeZone) {
      const localDateTime = utcToZonedTime(utcDateTime, localTimeZone);
      const formattedLocalTime = format(localDateTime, 'HH:mm:ss');

      return formattedLocalTime;
    },
    getUtcDateTime(localDate, localTime, localTimeZone) {
      const localDateTime = this.parseISO(localDate + 'T' + localTime);

      return zonedTimeToUtc(localDateTime, localTimeZone);
    },

    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    addTicketPackage() {
      const newId = this.ticketPackages.length + 1;
      this.ticketPackages.push({
        id: newId,
        ticketCount: 1,
        amountDollars: 1
      });
    },
    removeTicketPackage(index) {
      this.ticketPackages = this.ticketPackages.filter((tp) => tp.id !== index + 1);
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.errorMessage = null;

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      // reform ticket packages
      const ticketPackages = [];
      this.ticketPackages.forEach((element) => {
        ticketPackages.push({
          uuid: element.uuid || undefined,
          numTickets: element.ticketCount,
          price: Number(element.amountDollars)
        });
      });

      const params = {
        name: this.eventName,
        startDate: this.getUtcDateTime(this.startDate, this.startTime, this.organizationTimeZone),
        endDate: this.getUtcDateTime(this.endDate, this.endTime, this.organizationTimeZone),
        daysPerDraw: this.daysPerEvent,
        numDraws: this.eventCount,
        autoRecurring: this.autoRecurring,
        description: this.description,
        printIndividualTickets: this.printIndividualTickets,
        startTicketNumber: this.startingTicketNumber,
        ticketPackages,
        ticketFooter: this.ticketFooter ? this.ticketFooter : ''
      };

      try {
        if (!this.edit) {
          await axios.post('/goldrush-service/master-event', params);
        } else {
          await axios.patch(`/goldrush-service/master-event/${this.masterEventData.id}/edit`, params);
        }

        // refresh page to switch states
        this.$router.go();
      } catch (error) {
        this.createMasterEventFailed = error;

        this.errorMessage = this.parseError(error).message;
      }
    },

    resetForm() {
      this.eventName = this.masterEventData.name;
      this.description = this.masterEventData.description;
      this.eventCount = this.masterEventData.numDraws;
      this.daysPerEvent = this.masterEventData.daysPerDraw;
      this.autoRecurring = this.masterEventData.autoRecurring;
      this.printIndividualTickets = this.masterEventData.printIndividualTickets;
      this.startingTicketNumber = this.masterEventData.startTicketNumber;

      if (this.masterEventData.startDate) {
        this.startDate = this.getLocalDate(this.masterEventData.startDate, this.organizationTimeZone);
        this.startTime = this.getLocalTime(this.masterEventData.startDate, this.organizationTimeZone);
      }

      if (this.masterEventData.endDate) {
        this.endDate = this.getLocalDate(this.masterEventData.endDate, this.organizationTimeZone);
        this.endTime = this.getLocalTime(this.masterEventData.endDate, this.organizationTimeZone);
      }

      const ticketPackages = [];
      this.masterEventData.ticketPackages.forEach((element, index) => {
        const obj = {
          uuid: element.uuid,
          id: index + 1,
          ticketCount: element.numTickets,
          amountDollars: Number(element.price)
        };

        ticketPackages.push(obj);
      });

      this.ticketPackages = ticketPackages;
      this.ticketFooter = this.masterEventData.ticketFooter;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    calculateEndDate() {
      if (!this.startDate || this.eventCount <= 0 || this.daysPerEvent <= 0) {
        this.endDate = null;
        return;
      }

      const parsedStartDate = parse(this.startDate, 'yyyy-MM-dd', new Date());

      this.endDate = format(addDays(parsedStartDate, this.eventCount * this.daysPerEvent), 'yyyy-MM-dd');
    }
  }
};
</script>

<style scoped>
.tickets-for {
  text-align: center;
  margin-top: 0.5rem;
}
</style>

<style>
/* Remove checkmark in numTickets selectors as it makes them too small and cramped */
.valid-without-checkmark {
  padding-right: 0 !important;
  background-image: none !important;
}
</style>
